import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Form, FormControl, Button } from 'react-bootstrap';
import Time from '../../assets/icons/Time';
import Location from '../../assets/icons/Location';
import SingleUnit from '../../assets/images/single-unit.png';
import CarouselLayout from '../../containers/Layouts/CarouselLayout/CarouselLayout';
import { bca } from '../../assets/images/beliNIPLicon';
import ModalLayout from '../../containers/Layouts/ModalLayout/ModalLayout';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import withAuth from '../../services/withAuthProvider';
import toast from 'react-hot-toast';
import { format } from 'date-fns';
import { replaceEnter } from '../../utils/string';
import PaymentHistory from '../../components/Modals/PaymentHistory';

const defaultImage =
  'https://st3.depositphotos.com/23594922/31822/v/380/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg?forcejpeg=true';

export default function DetailTransaksiPenjualan() {
  const { id } = useParams();
  const { push } = useHistory();
  const [isOpenPaymentHistory, setIsOpenPaymentHistory] = useState(false);
  const [images, setImages] = useState([]);
  const [detail, setDetail] = useState([]);
  const [PPN, setPPN] = useState({
    show: false,
  });
  const dispatch = useDispatch();

  const openModalPaymentHistory = () => {
    setIsOpenPaymentHistory(true);
  };

  const closeModalPaymentHistory = () => {
    setIsOpenPaymentHistory(false);
  };

  function renderStatus(param) {
    switch (param) {
      case 'PENDING':
        return "<span class='badge-status warning'>Menunggu Pembayaran</span>";
      case 'PAID':
        return "<span class='badge-status success'>Lunas</span>";
      case 'WANPRESTASI':
        return "<span class='badge-status danger'>Wanprestasi</span>";
      case 'settlement':
        return "<span class='badge-status success'>Dibayar</span>";
      default:
        return '';
    }
  }

  const getTransaction = async () => {
    const imageURL = [];
    const response = await withAuth.getDataWithAuth('/api/trx-invoice-unit/selling/unit/' + id, {});
    if (response?.status === 200) {
      const dataTransaction = response?.data?.data;
      if (dataTransaction.stock_unit.pictures.length > 0) {
        dataTransaction.stock_unit.pictures.map((image, i) => imageURL.push({ image: image.image_url }));
        setImages(imageURL);
      }
      setDetail(dataTransaction);
      toast.success(response?.data?.message, {
        id: 'success-message',
        duration: 3000,
      });
    } else {
      toast.error(response?.data?.message, {
        id: 'error-message',
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    getTransaction();
  }, []);

  useEffect(() => {
    if (detail?.ppn_buyer !== null && detail?.ppn_buyer_include !== null)
      setPPN({
        show: true,
        include: detail?.ppn_buyer_include,
        value: detail?.ppn_buyer,
        total: detail?.ppn_buyer_rp,
        include_change: detail?.ppn_buyer_fee,
      });
  }, [detail]);

  return (
    <>
      <div className="page w-100 h-100">
        <section>
          <Container>
            <Row>
              <Col md={{ span: 8 }}>
                <div className="box-border mt-3 mb-3">
                  <div className="row data-invoice justify-content-between">
                    <div className="col-md-6 mb-2">
                      <span className="title">No.Invoice</span>
                      <br />
                      <span className="value">{detail?.transaction_number}</span>
                    </div>
                    <div className="col-md-3 mb-2">
                      <span className="title">Tanggal Pembelian</span>
                      <br />
                      <span className="value">
                        {detail?.created_at ? format(new Date(`${detail?.created_at}`), 'dd MMM yyyy, HH:mm') + ' WIB' : '-'}
                      </span>
                    </div>
                    <div className="col-md-3 mb-2">
                      <span className="title">Status</span>
                      <br />
                      <span
                        className="value"
                        dangerouslySetInnerHTML={{
                          __html: renderStatus(detail?.status || ''),
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
                <div className="box-border mb-3">
                  <div className="row data-invoice justify-content-between">
                    <div className="col-md-3 mb-2">
                      <span className="title">Nama Pembeli</span>
                      <br />
                      <span className="value">{detail?.buyer_name}</span>
                    </div>
                    <div className="col-md-3 mb-2">
                      <span className="title">Nomor Pembeli</span>
                      <br />
                      <span className="value">{detail?.buyer?.buyer_number}</span>
                    </div>
                    <div className="col-md-3 mb-2">
                      <span className="title">NIPL</span>
                      <br />
                      <span className="value">
                        {detail?.nipl?.nipl_code}
                        {detail?.nipl?.nipl}
                      </span>
                    </div>
                    <div className="col-md-3 mb-2">
                      <span className="title">Nomor Telepon</span>
                      <br />
                      <span className="value">{detail?.buyer_phone}</span>
                    </div>
                  </div>
                </div>
                <div className="box-border mb-3">
                  <Row>
                    <Col className="mb-2" md={{ span: 6 }}>
                      <CarouselLayout>
                        {images.length > 0 ? (
                          images.slice(0, 5).map((image, i) => (
                            <div>
                              {/* <img src={image.image} alt="Unit 5" /> */}
                              <div
                                className="with-background detail-unit-slider transaction-detail"
                                style={image.image ? { background: `url("${image.image}")` } : {}}
                              ></div>
                            </div>
                          ))
                        ) : (
                          <div>
                            <div
                              className="with-background detail-unit-slider transaction-detail"
                              style={defaultImage ? { background: `url(${defaultImage})` } : {}}
                            ></div>
                          </div>
                        )}
                      </CarouselLayout>
                    </Col>
                    <Col className="mb-2" md={{ span: 6 }}>
                      <div className="group justify-content-between">
                        <div>
                          <span className="nomor-lot">
                            Lot{' '}
                            {detail?.stock_unit?.lot_number && detail?.auction?.auction_lane_name
                              ? detail?.auction?.auction_lane_name + detail?.stock_unit?.lot_number
                              : '-'}
                          </span>
                          <div className="unit-name">
                            {detail?.stock_unit?.unit_name ||
                              detail?.stock_unit?.year +
                                ' ' +
                                detail?.stock_unit?.unit_maker?.name +
                                ' ' +
                                detail?.stock_unit?.unit_model?.name}
                          </div>
                        </div>
                        <div className="grade-unit-detail">
                          Grade
                          <br />
                          <span className="grade-detail">{detail?.stock_unit?.unit_inspection?.grade || '-'}</span>
                        </div>
                      </div>
                      <div>
                        <span className="unit-title">Harga Final</span>
                        <span className="unit-price mb-3">
                          Rp {detail?.final_price ? Number(detail?.final_price).toLocaleString() : '-'}
                        </span>
                        <div>
                          <a className="fw-semibold no-underline" onClick={() => push('/unit-lelang/' + detail?.stock_unit?.id)}>
                            Lihat Detail Unit
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="box-lelang mb-3">
                  <div className="row">
                    <div
                      className="col-md-6"
                      style={{
                        paddingLeft: '20px',
                        paddingTop: '10px',
                        paddingRight: '20px',
                      }}
                    >
                      <span className="lelang-title">{detail?.stock_unit?.location?.name || '-'}</span>
                      <div className="lelang-date" style={{ color: '#1E1E1F' }}>
                        {detail?.auction?.date_start && detail?.auction?.time_start
                          ? format(new Date(`${detail?.auction.date_start} ${detail?.auction.time_start}`), 'dd MMM yyyy')
                          : '-'}
                      </div>
                    </div>
                    <div
                      className="col-md-6"
                      style={{
                        paddingLeft: '20px',
                        paddingTop: '10px',
                        paddingRight: '20px',
                      }}
                    >
                      <div className="lelang-time">
                        <Time />{' '}
                        <span>
                          {detail?.auction?.date_start && detail?.auction?.time_start
                            ? format(new Date(`${detail.auction.date_start} ${detail.auction.time_start}`), 'HH:mm')
                            : '-'}{' '}
                          -{' '}
                          {detail?.auction?.date_start && detail?.auction?.time_finish
                            ? format(new Date(`${detail.auction.date_start} ${detail.auction.time_finish}`), 'HH:mm')
                            : '-'}
                        </span>
                      </div>
                      <div className="lelang-location">
                        <Location />{' '}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: detail?.stock_unit?.location?.detail ? replaceEnter(detail?.stock_unit?.location?.detail) : '-',
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={{ span: 4 }}>
                <div className="box-border summary-transaction mb-3">
                  <div className="title">Ringkasan</div>
                  <div>
                    <div className="amount-item mb-3">
                      <div className="d-flex justify-content-between mb-1">
                        <div className="item-name">Harga Terbentuk Lelang</div>
                        <div className="item-amount">Rp {detail?.final_price ? Number(detail?.final_price).toLocaleString() : '-'}</div>
                      </div>
                      {PPN.show && (
                        <div className="d-flex justify-content-between mb-1">
                          <div className="item-name">PPN {PPN.value && `${PPN.value} %`}</div>
                          <div className="item-amount">Rp {PPN.total ? Number(PPN.total).toLocaleString() : 0}</div>
                        </div>
                      )}
                      <div className="d-flex justify-content-between mb-1">
                        <div className="item-name">Biaya Admin</div>
                        <div className="item-amount">Rp {detail?.admin_fee ? Number(detail?.admin_fee).toLocaleString() : '-'}</div>
                      </div>
                      {detail?.service_fee ? (
                        <div className="d-flex justify-content-between mb-1">
                          <div className="item-name">Biaya Layanan</div>
                          <div className="item-amount">Rp {detail?.service_fee ? Number(detail?.service_fee).toLocaleString() : '-'}</div>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="d-flex justify-content-between border-bottom-amount">
                        <div className="item-name">Settlement</div>
                        <div className="item-amount">(Rp {detail?.deposit ? Number(detail?.deposit).toLocaleString() : 0})</div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="item-name">Total Diterima</div>
                        <div className="item-amount">Rp {detail?.grand_total ? Number(detail?.grand_total).toLocaleString() : '-'}</div>
                      </div>
                      <div className="d-flex justify-content-between border-bottom-amount">
                        <div className="item-name">Sudah Dibayar</div>
                        <div className="item-amount">
                          (Rp {detail?.current_payment ? Number(detail?.current_payment).toLocaleString() : 0})
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="item-name">Sisa Pembayaran</div>
                        <div className="item-amount">
                          Rp{' '}
                          {detail?.grand_total
                            ? Number(parseInt(detail?.grand_total) - parseInt(detail?.current_payment || 0)).toLocaleString()
                            : 0}
                        </div>
                      </div>
                    </div>

                    {detail?.status !== 'PAID' ? (
                      <div className="amount-item mb-3">
                        <div className="d-flex justify-content-between mb-1">
                          <div className="item-name">Pembayaran Ke</div>
                          <div className="item-amount">{detail?.payments?.length + 1}</div>
                        </div>
                        <div className="d-flex justify-content-between mb-1">
                          <div className="item-name">Jatuh Tempo</div>
                          <div className="item-amount text-primary">
                            {detail?.expiry_date ? format(new Date(`${detail.expiry_date}`), 'dd MMM yyyy, HH:mm') : '-'}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className="btn-payment-history cursor-pointer" onClick={openModalPaymentHistory}>
                      <div>Riwayat Pembayaran</div>
                      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.17192 7.00072L0.221924 2.05072L1.63592 0.636719L7.99992 7.00072L1.63592 13.3647L0.221924 11.9507L5.17192 7.00072Z"
                          fill="#1E1E1F"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <ModalLayout
        show={isOpenPaymentHistory}
        handleClose={closeModalPaymentHistory}
        title={'Riwayat Pembayaran'}
        className="modal-payment-history"
      >
        <PaymentHistory data={detail} />
      </ModalLayout>
    </>
  );
}
