import React, { useState, useEffect } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import withAuth from '../../services/withAuthProvider';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { unitSelector } from '../../redux/unit.slice';
import NumberFormat from 'react-number-format';
import MultiSelect from '../MultiSelect/MultiSelect';

export default function ProxyBid(props) {
  const [dataNIPL, setDataNIPL] = useState([]);
  const { push } = useHistory();
  const unit = useSelector(unitSelector);

  const handleClick = (path) => {
    push(path);
  };

  const getNIPL = async () => {
    const response = await withAuth.getDataWithAuth(`/api/nipl/proxy-bid/${unit?.current_auction_id}`, {});
    if (response?.status === 200) {
      setDataNIPL(response.data.data.data);
      console.log('NIPL LIST', response.data.data.data);
    } else {
      toast.error(response.data.message, {
        id: 'error-message',
        duration: 3000,
      });
    }
  };

  function isSpecialNIPL() {
    return unit?.is_special_nipl === 'true' && unit?.number_of_nipl > 1;
  }

  useEffect(() => {
    getNIPL();
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    nipl_buyer_id: Yup.string().required('Silahkan memilih NIPL'),
    offer_price: Yup.string().required('Silahkan mengisi harga tawar'),
  });
  const validationSpecialNiplSchema = Yup.object().shape({
    nipl_buyer_id: Yup.array().required('Silahkan memilih NIPL'),
    offer_price: Yup.string().required('Silahkan mengisi harga tawar'),
  });

  // functions to build form returned by useForm() hook
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(isSpecialNIPL() ? validationSpecialNiplSchema : validationSchema),
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    let dataProxyBid = null;
    const offer_price = data.offer_price.split(',').join('');
    if (isSpecialNIPL()) {
      dataProxyBid = {
        stock_unit_id: unit?.id,
        nipl_buyer_id: data?.nipl_buyer_id.map((nipl) => nipl?.value),
        offer_price: offer_price,
      };
    } else {
      dataProxyBid = new FormData();
      dataProxyBid.append('stock_unit_id', unit.id);
      dataProxyBid.append('nipl_buyer_id', data.nipl_buyer_id);
      dataProxyBid.append('offer_price', offer_price);
    }

    const loading = toast.loading('Mengirimkan...');

    const response = await withAuth.postDataWithAuth(isSpecialNIPL() ? `/api/proxy-bid/add-special` : '/api/proxy-bid/add', dataProxyBid);
    if (response?.status === 200) {
      toast.dismiss(loading);
      toast.success(response.data.message, {
        id: 'success-message',
        duration: 3000,
      });
      props.handleClose();
    } else {
      toast.dismiss(loading);
      toast.error(response?.data?.errors?.details || response?.data?.message, {
        id: 'error-message',
        duration: 3000,
      });
    }
    e.target.reset();
  };

  return (
    <>
      <div className="form-modal">
        <Form onSubmit={handleSubmit(onSubmit)}>
          {isSpecialNIPL() ? (
            <>
              <Controller
                control={control}
                name="nipl_buyer_id"
                render={({ field: { onChange, name, value = [] } }) => (
                  <>
                    <MultiSelect
                      placeholder="Pilih NIPL"
                      name={name}
                      noOptionsMessage="Tidak ada NIPL"
                      maxLimit={unit?.number_of_nipl}
                      onChange={onChange}
                      options={dataNIPL.map((data) => ({ label: data?.nipl_code + ' ' + data?.nipl, value: data?.id, data: data }))}
                      isOptionDisabled
                      value={value}
                    />
                    {errors.nipl_buyer_id?.message && (
                      <>
                        <span className="error-message">{errors.nipl_buyer_id?.message}</span>
                        <br />
                      </>
                    )}
                    <Form.Text className="text-muted">
                      Belum punya NIPL?{' '}
                      <span className="text-primary cursor-pointer" onClick={() => handleClick('/beli-nipl')}>
                        Beli NIPL
                      </span>
                    </Form.Text>
                  </>
                )}
              />
            </>
          ) : (
            <Form.Group className="form-group" controlId="formBasicEmail">
              <Form.Label className="required">NIPL</Form.Label>
              <Form.Select placeholder="Pilih NIPL" {...register('nipl_buyer_id')}>
                <option value="">Pilih NIPL</option>
                {dataNIPL.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.nipl_code + ' ' + row.nipl}
                  </option>
                ))}
              </Form.Select>
              {errors.nipl_buyer_id?.message && (
                <>
                  <span className="error-message">{errors.nipl_buyer_id?.message}</span>
                  <br />
                </>
              )}
              <Form.Text className="text-muted">
                Belum punya NIPL?{' '}
                <span className="text-primary cursor-pointer" onClick={() => handleClick('/beli-nipl')}>
                  Beli NIPL
                </span>
              </Form.Text>
            </Form.Group>
          )}

          <Form.Group className="form-group" controlId="formBasicPassword">
            <Form.Label className="required">Harga Tawar</Form.Label>
            <InputGroup>
              <InputGroup.Text id="basic-addon1">Rp</InputGroup.Text>
              <Controller
                control={control}
                name="offer_price"
                render={({ field: { onChange, name, value } }) => (
                  <NumberFormat className="form-control" thousandSeparator={true} name={name} value={value} onChange={onChange} />
                )}
              />
            </InputGroup>
            {errors.offer_price?.message && (
              <>
                <span className="error-message">{errors.offer_price?.message}</span>
                <br />
              </>
            )}
            <Form.Text className="text-muted">Kelipatan bid 500.000</Form.Text>
          </Form.Group>

          <div className="group">
            <Button className="cancel" variant="default" type="reset" onClick={props.handleClose}>
              Batal
            </Button>
            <Button variant="primary" type="submit">
              Tambahkan
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}
