import { format } from 'date-fns';
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import SpecialNIPL from '../../../assets/icons/SpecialNIPL';
import ModalLayout from '../../../containers/Layouts/ModalLayout/ModalLayout';
import withAuth from '../../../services/withAuthProvider';
import SettingTransaksiBayar from '../../Modal/SettingTransaksiBayar/SettingTransaksiBayar';
import MoreButton from '../../MoreButton/MoreButton';
import { SkeletonTransaction } from '../../Skeleton/Skeleton';

const defaultImage =
  'https://st3.depositphotos.com/23594922/31822/v/380/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg?forcejpeg=true';

const ChildPurchase = (props) => {
  const detail = props.data;
  function renderStatus(param) {
    switch (param) {
      case 'PENDING':
        return "<span class='badge-status warning'>Menunggu Pembayaran</span>";
      case 'PAID':
        return "<span class='badge-status success'>Lunas</span>";
      case 'WANPRESTASI':
        return "<span class='badge-status danger'>Wanprestasi</span>";
      case 'OVERPAID':
        return "<span class='badge-status danger'>Overpaid</span>";
      default:
        return '';
    }
  }
  return (
    <div className="card-transaksi position-relative">
      <div className="header">
        <Row>
          <div className="col-md-6">
            <span className="title d-none d-sm-block mb-2">No.Invoice</span>
            <span className="value invoice-number">{detail?.transaction_number}</span>
          </div>
          <div className="col-md-3">
            <span className="title d-none d-sm-block mb-2">Tanggal Pembelian</span>
            <span className="value">
              {detail?.created_at ? format(new Date(`${detail?.created_at}`), 'dd MMM yyyy, HH:mm') + ' WIB' : '-'}
            </span>
          </div>
          <div className="col-md-3">
            <span className="title d-none d-sm-block mb-2">Status</span>
            <span
              className="value"
              dangerouslySetInnerHTML={{
                __html: renderStatus(detail?.result?.result_selling || ''),
              }}
            ></span>
          </div>
        </Row>
      </div>
      <div className="body">
        <Row>
          <Col md={6}>
            <div className="d-flex info-unit">
              <div>
                <div
                  className="unit-image"
                  style={
                    detail?.stock_unit?.pictures?.length > 0
                      ? {
                          position: 'relative',
                          float: 'left',
                          background: `url("${detail?.stock_unit?.pictures[0].image_url}")`,
                        }
                      : {
                          position: 'relative',
                          float: 'left',
                          background: `url(${defaultImage})`,
                        }
                  }
                ></div>
              </div>
              <div style={{ marginLeft: '10px' }}>
                <div className="unit-name">
                  {detail?.stock_unit?.unit_name ||
                    detail?.stock_unit?.year + ' ' + detail?.stock_unit?.unit_maker?.name + ' ' + detail?.stock_unit?.unit_model?.name}
                </div>
                <span className="title">Harga Final</span>
                <br />
                <span className="harga final">Rp {detail?.final_price ? Number(detail?.final_price).toLocaleString() : '-'}</span>
                <br />
                {detail.result?.number_of_nipl > 1 && (
                  <span className="nomor-lot nipl">
                    <span className="icon-nipl">
                      <SpecialNIPL />
                    </span>
                    Special NIPL
                    {/* <span className='text orange'>
                    </span> */}
                  </span>
                )}
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="row info-tagihan">
              <div className="col-md-6">
                <span className="title">Total Tagihan</span>
                <br />
                <span className="harga tagihan">Rp {detail?.grand_total ? Number(detail?.grand_total).toLocaleString() : '-'}</span>
                <br />
                <span className="title">Tanggal Kedaluwarsa</span>
                <br />
                <span className="tanggal">
                  {detail?.expiry_date ? format(new Date(`${detail?.expiry_date}`), 'dd MMM yyyy, HH:mm') + 'WIB' : '-'}{' '}
                </span>
              </div>
              <div className="col-md-6 button">
                {
                  // detail?.payment_bank_name !== null &&
                  // detail?.payment_bank_virtual_account !== null &&
                  detail?.status !== 'PAID' &&
                  detail?.result?.result_selling !== 'WANPRESTASI' &&
                  detail?.result?.result_selling !== 'PAID' ? (
                    <a className="btn-bayar cursor-pointer disable-copy" onClick={props.openModal}>
                      Bayar
                    </a>
                  ) : (
                    ''
                  )
                }
                <a className="btn-detail cursor-pointer disable-copy" onClick={props.onClick}>
                  Lihat detail ringkasan
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const initialPaginate = {
  page: 1,
  total: 1,
};

const PurchaseTransactionContent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [detail, setDetail] = useState([]);
  const [transaction, setTransaction] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginate, setPaginate] = useState({ ...initialPaginate });
  const { push } = useHistory();

  const closeModal = () => {
    setIsOpen(false);
    setTransaction();
  };

  const loadMore = () => {
    setPaginate((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const getData = async (page) => {
    const params = {
      page: paginate.page,
      page_size: 5,
    };

    const response = await withAuth.getDataWithAuth('/api/trx-invoice-unit/transaction/unit', params);
    if (response?.status === 200) {
      const res = response.data.data;
      setData((last_data) => [...last_data, ...res.data]);

      setPaginate((prev) => ({
        ...prev,
        total: res.last_page,
      }));

      // toast.success(response?.data.message, {
      //     id: 'success-message',
      //     duration: 3000
      // });
      setIsLoading(false);
    } else {
      toast.error(response?.data.message, {
        id: 'error-message',
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    getData().finally(() => setIsLoading(false));
  }, [paginate.page]);

  useEffect(() => {
    const trx = data.find((purchase) => purchase.id === parseInt(transaction));
    if (typeof trx !== 'undefined') {
      setIsOpen(true);
      setDetail(trx);
    }
  }, [transaction]);

  return (
    <>
      <Row>
        {isLoading
          ? new Array(3).fill(1).map((el, idx) => (
              <div key={idx}>
                <SkeletonTransaction />
              </div>
            ))
          : data.map((el, idx) => (
              <Col key={idx} md={12}>
                <ChildPurchase
                  data={el}
                  onClick={() => push('/pengaturan/transaksi/pembelian/' + el.id)}
                  openModal={() => setTransaction(el.id)}
                />
              </Col>
            ))}
        {paginate.page !== paginate.total && <MoreButton onClick={loadMore} />}
        {data.length === 0 && isLoading === false && (
          <div className="px-3 py-2 font-sm text-center text-secondary w-100">Tidak ada transaksi</div>
        )}
      </Row>
      <ModalLayout show={isOpen} handleClose={closeModal} title="Menunggu Pembayaran" className="">
        <SettingTransaksiBayar data={detail} />
      </ModalLayout>
    </>
  );
};

export default PurchaseTransactionContent;
