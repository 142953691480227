import React from 'react';
import Select, { components } from 'react-select';

const MultiSelect = ({
  name,
  className = false,
  value,
  noOptionsMessage = '',
  placeholder,
  onChange,
  isOptionDisabled = false,
  maxLimit,
  options = [],
}) => {
  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>{noOptionsMessage}</span>
      </components.NoOptionsMessage>
    );
  };
  return (
    <Select
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          ':active': {
            borderColor: 'red',
          },
          borderColor: state.isFocused || state?.menuIsOpen ? 'red' : 'gray', // Change color when open or focused
          boxShadow: state.isFocused || state?.menuIsOpen ? '0 0 0 4px #f89589' : 'none',
          ':hover': {
            borderColor: '#e78b80',
          },
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? '#f89589' : state.isFocused ? '#f89589' : 'white', // Change background on hover/focus
          color: state.isSelected ? 'white' : 'black', // Change text color
          cursor: 'pointer', // Change cursor to pointer on hover
        }),
      }}
      isMulti
      isOptionDisabled={isOptionDisabled ? () => value.length >= maxLimit : () => {}}
      className={className ? className : 'basic-multi-select'}
      classNamePrefix="select"
      options={options}
      components={{ NoOptionsMessage }}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default MultiSelect;
