import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import withAuth from '../../../services/withAuthProvider';
import toast from 'react-hot-toast';
import { PasswordField } from '../../PasswordField/PasswordField';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import { actionsAuth, tokenSelector } from '../../../redux/auth.slice';
import { useDispatch, useSelector } from 'react-redux';
import { validatePassword } from '../../../utils/string';

export default function UbahKataSandi() {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const [statusValidation, setStatusValidation] = useState(null);

  const validationSchema = Yup.object().shape({
    old_password: Yup.string()
      .transform((x) => (x === '' ? undefined : x))
      .concat(Yup.string().required('Silahkan mengisi kata sandi lama')),
    password: Yup.string()
      .transform((x) => (x === '' ? undefined : x))
      .concat(Yup.string().required('Silahkan mengisi kata sandi baru'))
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        'Kata Sandi harus memiliki minimal 8 karakter, terdapat huruf besar dan kecil serta mengandung angka'
      )
      .min(8, 'Kata sandi harus minimal 8 karakter'),
    new_password_confirmation: Yup.string()
      .transform((x) => (x === '' ? undefined : x))
      .concat(Yup.string().required('Silahkan mengisi konfirmasi kata sandi'))
      .when('password', (password, schema) => {
        if (password) return schema.required('Silahkan mengisi konfirmasi kata sandi baru');
      })
      .oneOf([Yup.ref('password')], 'Kata sandi harus sama'),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const dataPassword = new FormData();
    dataPassword.append('old_password', data.old_password);
    dataPassword.append('password', data.password);
    dataPassword.append('password_confirmation', data.new_password_confirmation);

    const loading = toast.loading('Mengirimkan...');
    const response = await withAuth.postDataWithAuth('/api/account/change-password', dataPassword);
    if (response?.status === 200) {
      const responseUserData = await withAuth.getDataWithAuth('/api/account/user', {});
      if (responseUserData.status === 200) {
        dispatch(
          actionsAuth.setUser({
            token: token,
            user: responseUserData.data.data,
          })
        );
      }
      toast.dismiss(loading);
      toast.success(response?.data?.message, {
        id: 'success-message',
        duration: 3000,
      });
      reset({ old_password: '', password: '', new_password_confirmation: '' });
    } else {
      toast.dismiss(loading);
      toast.error(response?.data?.message, {
        id: 'error-message',
        duration: 3000,
      });
    }
  };

  return (
    <>
      <h4 className="sub-title">Ubah Kata Sandi</h4>
      <Row>
        <Col md={6}>
          <Form className="form-caready" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <Controller
                control={control}
                name="old_password"
                render={({ field: { onChange, name, value } }) => (
                  <PasswordField
                    id="old_password"
                    name={name}
                    placeholder="Masukan Password Anda"
                    onChange={onChange}
                    label="Password Lama"
                    value={value}
                  />
                )}
              />
              {errors.old_password?.message && <span className="error-message">{errors.old_password?.message}</span>}
            </div>
            <div className="mb-3">
              <Controller
                control={control}
                name="password"
                render={({ field: { onChange, name, value } }) => (
                  <PasswordField
                    id="password"
                    validation={statusValidation}
                    name={name}
                    placeholder="Masukan Password Baru Anda"
                    onChange={(val) => {
                      setStatusValidation(validatePassword(val?.target?.value));
                      onChange(val);
                    }}
                    label="Password Baru"
                    value={value}
                    extended
                  />
                )}
              />
              {errors.password?.message && <span className="error-message">{errors.password?.message}</span>}
            </div>
            <div className="mb-3">
              <Controller
                control={control}
                name="new_password_confirmation"
                render={({ field: { onChange, name, value } }) => (
                  <PasswordField
                    id="new_password_confirmation"
                    name={name}
                    placeholder="Masukan Password Konfirmasi"
                    onChange={onChange}
                    label="Konfirmasi Password Baru"
                    value={value}
                  />
                )}
              />
              {errors.new_password_confirmation?.message && (
                <span className="error-message">{errors.new_password_confirmation?.message}</span>
              )}
            </div>
            <Button className="button-login mb-3" style={{ display: 'block !important', width: '100%' }} variant="primary" type="submit">
              Simpan Kata Sandi Baru
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
}
